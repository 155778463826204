<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="mentor-detail">
                <div class="mentor-info">
                    <div class="mentor-info__avatar">
                        <img src="https://medias.zaih.com/FjLKySaB94e9ptyV0lnx06XLutpE" alt="">
                    </div>
                    <div class="mentor-info__main">
                        <div class="mentor-info__info">
                            <div class="mentor-info__name">张老三</div>
                            <div class="mentor-info__city">
                                <i class="iconfont icon-chufadihedidian"></i>
                                北京
                            </div>
                            <div class="share-btn iconfont icon-fenxiang6"></div>
                        </div>
                        <div class="mentor-info__title">心资本Soul  Capital 合伙人</div>
                        <div class="mentor-info__item">
                            <span class="name">评分：</span>
                            <span class="mentor-info_content">9.3</span>
                        </div>
                        <div class="mentor-info__item">
                            <span class="name">响应率：</span>
                            <span class="mentor-info_content">中</span>
                        </div>
                        <div class="mentor-info__item">
                            <span class="name">约聊人数：</span>
                            <span class="mentor-info_content">5300</span>
                        </div>
                    </div>
                </div>
                <!-- tab栏 -->
                <ul class="nav-list">
                    <li class="nav-item is-active">话题详情</li>
                    <li class="nav-item ">专业成就</li>
                    <li class="nav-item ">学院评价<span>(10)</span></li>
                </ul>
                <!-- 话题详情 -->
                <ul class="topic-list">
                    <li class="topic-item" v-for="(item,index) in 3">
                        <h3 class="topic-item__title">互联网人转型做风投的第一课</h3> 
                        <div class="topic-item__main"><div class="topic-item__content is-expanded"><p>以前做风投的是金融出身的人，现在做风投懂业务是关键。我曾经在百度做产品经理，后来在百度做战略分析，之后转型做风投。我相信，现在有一些互联网人和创业者也在考虑如何转型做投资：如何转行做风险投资投资需要做哪些准备投资找谁推荐每家投资机构什么风格做风投的一天是什么样子的作为过来人，愿意和大家一同分享其中的心得感受。做风险投资前需要知道的二三事。</p> <!----></div> 
                        <span class="btn-fold is-expanded" style="display: flex;">
                            展开
                            <i class="iconfont icon-xiajiantou"></i>
                        </span> 
                        <div class="topic-item__price"><span class="price">
                            ￥800
                        </span></div></div>
                    </li>
                </ul>
                <!-- 专业成就 -->
                <div class="mentor-intro">
                    <h3 class="section-title">专业成就</h3>
                    <p class="section-content">
                        吴炳见，三年互联网产品经理+战略分析经验，十年风险投资经验，剑桥大学计算机科学硕士。之前因为试用在行，注册成为行家，不知不觉已经和两三百人交流过，能用自己的认知帮到一些朋友，是个很开心的过程。毕业后，我曾在百度工作三年，是公司里第一批移动互联网产品经理，之后就职于百度商业分析部，从事行业和战略研究。 
                        在联想之星和险峰长青从事风险投资十年时间。之后参与创立Soul Capital心资本，是新一代风险投资机构，任合伙人。
                        投资和管理过几十个项目，担任十多个项目的董事，经历了这些项目从天使轮到pre-IPO，从估值几百万美金到大几亿美金、10亿美金+，从月收入为零到月收入数亿。也因为深度参与这些项目，看到了创始人怎样四年转型三次，从账上只剩200万人民币，到抓到月收入数亿的机会；看到创始人在被60家投资机构拒绝，怎样在第61家投资机构拿到投资。
                        在这里，我们可以交流这些话题：
                        1、创业中怎么找到好的商业机会，怎么做0到1的突破，1到10会遇到什么问题
                        2、你的项目是否适合融资，怎么搞定融资，投资机构的思维方式是什么
                        3、创业中难免遇到危机和转型，怎么应对，怎么对当下问题作出判断，怎么面对现实
                        4、也许你考虑入行做投资，投资行业过去十年有什么变化，对候选人有什么要求，面试是聊什么话题，怎么选择适合自己的机构
                        5、也许你遇到职场的转型和选择，如何找到自己的特长点，如何选行业，怎样做准备，怎样实现转型
                        6、AI行业有怎样的机会，如何选择AI创业的切入点，AI项目怎么融资？
                        我的公众号：AI大航海
                        可以在上面看我的AI行业观点
                    </p>
                </div>
                <!-- 学员评价 -->
                <div class="comments">
                    <h3 class="section-title">学员评价</h3>
                    <div class="comment-list">
                        <div class="comment-item">
                            <div class="comment-item__header">
                                <img src="https://medias.zaih.com/4demvw1nordpfxfche0h62cdg4r93vwm" class="comment-item__avatar" alt="">
                                <span class="comment-item__name">Laury</span>
                                <span class="comment-item__date">2023.08.25</span>
                            </div>
                            <p class="comment-item__content">感谢吴老师，一小时的交流受益颇多，点醒了我产品定价，见投资人过程中存在的两个重点问题，十分感谢。</p>
                            <div class="comment-item__topic">参与话题：如何一个月内拿到投资</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 选择约聊方式 -->
            <div class="consult-wrapper">
                <div class="topics">
                    <div class="title">选择约聊话题</div>
                    <div class="topic-container">
                        <div class="topic-items active" v-for="(item,index) in 3">
                            <span class="text">互联网人转型做风投的第一课</span>
                        </div>
                    </div>
                </div>
                <div class="consult-types">
                    <div class="title">
                        选择约聊方式
                    </div>
                    <div class="type-container">
                        <div class="consult-type-item">
                            <div class="item-main inactive">
                                <div class="item_title">语音电话</div> 
                                <div class="des">1对1通话(约60分钟)</div>
                                 <div class="price-container">
                                    <div class="disabled">暂不可约</div> 
                                </div>
                            </div> 
                        </div>
                        <div class="consult-type-item">
                            <div class="item-main inactive">
                                <div class="item_title">线下约见<span>(北京)</span></div> 
                                <div class="des">1对1面谈(约60分钟)</div>
                                 <div class="price-container">
                                    <div class="price-wrapper">
                                        <span class="actual-price">
                                            ￥800
                                        </span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="picker-footer">
                    <button class="confirm-btn active">免费预约</button>
                    <button class="btn-kefu">
                        <i class="iconfont icon-kefu-copy-copy" style="font-size: 30px;color: #f85f48;"></i>
                        客服
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
    }
</script>

<style lang="less" scoped>
.main {
    position: relative;
    // width: 100%;
    width: 1068px;
    margin: 40px auto 0;
}
/* 导师信息 */
.mentor-detail {
    .mentor-info {
        display: flex;
        align-items: flex-start;
        margin-bottom: 60px;
        width: 628px;
        .mentor-info__avatar {
            width: 200px;
            height: 200px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.mentor-info__main {
    flex: 1;
    padding-left: 20px;
}
.mentor-info__city, .mentor-info__info {
    display: flex;
}
.mentor-info__info {
//    justify-content: space-between;
}
.mentor-info__name {
    line-height: 36px;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 1.2px;
    color: #222;
    max-width: 360px;
}
.mentor-info__city {
    font-size: 14px;
    flex: none;
    align-items: flex-start;
    margin: 10px 10px 0 12px;
    line-height: 16px;
    color: #c4c4c4;
}
.mentor-detail .mentor-info .share-btn {
    margin-top: 4px;
    margin-left: auto;
    font-size: 28px;
    color: #979797;
}
.mentor-info__title {
    margin-top: 4px;
    margin-bottom: 20px;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: .6px;
    color: #222;
}
.mentor-info__item {
    display: flex;
    align-items: center;
    margin-top: 8px;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: .4px;
}
.mentor-info__item .name {
    display: inline-flex;
    align-items: center;
    width: 72px;
    color: #333;
}
.mentor-info__item .mentor-info_content {
    margin-left: 10px;
    color: #b37d74;
}
/* tab栏 */
.nav-list {
    // position: relative;
    position: sticky;
    display: flex;
    align-items: stretch;
    height: 60px;
    background: #fff;
    width: 628px;
    // top: 60px;
    top: 0;
    margin-top: 0px;
    transition: top .3s;
    z-index: 100;
}
.nav-item {
    
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0px;
    font-size: 14px;
    line-height: 60px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    color: #333;
}
.mentor-detail .nav-list .nav-item.is-active {
    color: #333;
    border-bottom: 4px solid #f85f48;
}
/* 话题详情 */
.topic-list {
    width: 628px;
    margin-top: 30px;
    margin-bottom: 80px;
}
.topic-list .topic-item {
    margin-bottom: 29px;
}
.topic-item {
    .topic-item__title {
        border-left: 4px solid #c4c4c4;
        height: 48px;
        line-height: 48px;
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #222;
        background: #ececec;
    }
    .topic-item__main {
        position: relative;
        padding: 12px 24px;
        background: #fff;
    }
    .topic-item__content {
        position: relative;
        line-height: 22px;
        font-size: 14px;
        letter-spacing: 1px;
        color: #333;
        white-space: pre-line;
        word-break: break-all;
        max-height: 112px;
        overflow: hidden;
    }
    .topic-item__content.is-expanded {
        max-height: none;
    }
    .btn-fold {
        display: block;
        position: absolute;
        left: 24px;
        bottom: 12px;
        width: 48px;
        line-height: 37px;
        font-size: 14px;
        letter-spacing: .4px;
        text-align: left;
        color: #8996ca;
        cursor: pointer;
    }
    .topic-item__price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 12px;
        height: 37px;
    }
    .topic-item__price .price, .topic-item__price .unit {
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        letter-spacing: 1px;
        color: #f85f48;
        font-family: DIN,sans-serif;
    }
}
/* 专业成就 */
.mentor-intro {
    width: 628px;
    margin-top: 80px;
}
.section-title {
    font-size: 24px;
    letter-spacing: 1.4px;
}
.section-content {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 1px;
    color: #333;
    white-space: pre-line;
    word-break: break-all;
    text-align: justify;
}
.mentor-intro .section-content {
    margin-top: 24px;
}
/* 学员评价 */
.comments {
    width: 628px;
    margin-top: 80px;
}
.section-title {
    font-size: 24px;
    letter-spacing: 1.4px;
}
.section-title, .small-title {
    line-height: 32px;
    font-weight: 600;
    color: #222;
}
.comments .comment-list {
    margin-top: 24px;
}
.comment-item__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.comment-item__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.comment-item__name {
    flex: 1 0 auto;
    margin-left: 14px;
    height: 32px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.78;
    letter-spacing: 1px;
    color: #222;
}
.comment-item__date {
    margin-left: auto;
    height: 18px;
    font-size: 12px;
    line-height: 1.29;
    letter-spacing: .4px;
    color: #c4c4c4;
}
.comment-item__content {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 1px;
    color: #333;
}
.comment-item__topic {
    margin-top: 20px;
    line-height: 18px;
    font-size: 14px;
    letter-spacing: .4px;
    color: #c4c4c4;
}
/* 选择约聊方式 */
.consult-wrapper {
    position: fixed;
    top: 174px;
    bottom: auto;
    right: 50%;
    margin-right: -534px;
    z-index: 100;
    font-size: 14px;
    box-sizing: border-box;
    width: 380px;
    padding: 20px 30px;
    background-color: #fff;
    .title {
        color: #999;
        letter-spacing: .44px;
        display: flex;
        justify-content: space-between;
    }
    .topic-container {
        margin-top: 8px;
        max-height: 260px;
        overflow: auto;
    }
    .topic-items {
        cursor: pointer;
        height: 40px;
        border: 1px solid #ececec;
        margin-bottom: 8px;
        color: #333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 6px;
        letter-spacing: .78px;
    }
    .topic-items.active {
        color: #f85f48;
        border: 1px solid #f85f48;
    }
    .consult-types {
        margin-top: 16px;
    }
    .type-container {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        flex-wrap: wrap;
    }
    .consult-type-item {
        cursor: pointer;
        width: 150px;
        margin-bottom: 20px;
        position: relative;
    }
    .item-main {
        background-color: #fff;
        padding: 6px;
        box-sizing: border-box;
        border: 1px solid #ececec;
    }
    .item-main.inactive {
        background-color: #f6f6f6;
        border: 1px dashed #ececec;
        color: #999;
    }
    .consult-type-item .item-main .item_title {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #333;
        letter-spacing: 0;
        display: flex;
        align-items: center;
        span {
            font-size: 12px;
        }
    }
    .item-main.inactive .item_title {
        color: #999;
    }
    .item-main .des {
        font-size: 12px;
        line-height: 18px;
        color: #999;
        letter-spacing: 0;
    }
    .item-main .price-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
        line-height: 21px;
    }
    .item-main .price-container .disabled {
        font-weight: 600;
        font-size: 14px;
        color: #999;
        letter-spacing: .44px;
    }
    .item-main .price-container .price-wrapper {
        font-family: DIN,sans-serif;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        display: flex;
    }
    .item-main .price-container .actual-price {
        color: #333;
        margin-right: 4px;
    }
    .consult-type-item .item-main {
        background-color: #fff;
        padding: 6px;
        box-sizing: border-box;
        border: 1px solid #ececec;
    }
    .item-main.active {
        border: 1px solid #f85f48;
    }
    .picker-footer {
        display: flex;
        justify-content: space-between;
        height: 50px;
    }
    .picker-footer .confirm-btn {
        width: 270px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #999;
        font-size: 16px;
        letter-spacing: .88px;
        color: #fff;
        cursor: pointer;
    }
    .picker-footer .confirm-btn.active {
        background-color: #f85f48;
    }
    .picker-footer .btn-kefu {
        width: 34px;
        height: 50px;
        font-size: 12px;
        letter-spacing: .4px;
        color: #999;
    }
}
button {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background: none;
}
</style>